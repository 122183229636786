var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full p-6 bg-white rounded-lg" },
    [
      _c(
        "div",
        [
          _c("LabelForm", [
            _vm._v(_vm._s(_vm.$t("resorts.requirements.key")) + " *")
          ]),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "w-full border-grey-light",
            attrs: { name: "key" },
            model: {
              value: _vm.requirement.key,
              callback: function($$v) {
                _vm.$set(_vm.requirement, "key", $$v)
              },
              expression: "requirement.key"
            }
          }),
          _c("span", { staticClass: "text-sm text-danger" }, [
            _vm._v(_vm._s(_vm.errors.first("key")))
          ])
        ],
        1
      ),
      _c(
        "div",
        [
          _c("LabelForm", [
            _vm._v(_vm._s(_vm.$t("resorts.requirements.type")) + " *")
          ]),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "w-full border-grey-light",
            attrs: { name: "type" },
            model: {
              value: _vm.requirement.type,
              callback: function($$v) {
                _vm.$set(_vm.requirement, "type", $$v)
              },
              expression: "requirement.type"
            }
          }),
          _c("span", { staticClass: "text-sm text-danger" }, [
            _vm._v(_vm._s(_vm.errors.first("type")))
          ])
        ],
        1
      ),
      _c("LocalizedContent", {
        attrs: {
          fieldsDefinition: [
            {
              name: "name",
              label: _vm.$t("resorts.requirements.name"),
              type: "text"
            },
            {
              name: "description",
              label: _vm.$t("resorts.requirements.description"),
              type: "textarea"
            }
          ]
        },
        model: {
          value: _vm.requirement.langs,
          callback: function($$v) {
            _vm.$set(_vm.requirement, "langs", $$v)
          },
          expression: "requirement.langs"
        }
      }),
      _c(
        "div",
        { staticClass: "mt-10 text-right" },
        [
          _c(
            "button",
            {
              staticClass:
                "inline-block mb-2 mr-2 rounded-lg sk-btn sk-btn-primary",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.handleSave.apply(null, arguments)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("shops.save")) + " ")]
          ),
          _c(
            "router-link",
            {
              staticClass:
                "inline-block mb-2 mr-2 rounded-lg sk-btn sk-btn-primary btn-border",
              attrs: { to: { name: "resort-requirements" } }
            },
            [_vm._v(" " + _vm._s(_vm.$t("shops.cancel")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }