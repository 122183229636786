<template>
    <div class="w-full p-6 bg-white rounded-lg">
        <div>
            <LabelForm>{{ $t("resorts.requirements.key") }} *</LabelForm>
            <vs-input class="w-full border-grey-light" name="key" v-model="requirement.key" v-validate="'required'"/>
            <span class="text-sm text-danger">{{ errors.first('key') }}</span>
        </div>
        
        <div>
            <LabelForm>{{ $t("resorts.requirements.type") }} *</LabelForm>
            <vs-input class="w-full border-grey-light" name="type" v-model="requirement.type" v-validate="'required'"/>
            <span class="text-sm text-danger">{{ errors.first('type') }}</span>
        </div>

        <LocalizedContent 
            v-model="requirement.langs" 
            :fieldsDefinition="[
                { name: 'name', label: $t('resorts.requirements.name'), type: 'text' },
                { name: 'description', label: $t('resorts.requirements.description'), type: 'textarea' },
            ]"
        />

        <div class="mt-10 text-right">
            <button class="inline-block mb-2 mr-2 rounded-lg sk-btn sk-btn-primary"
                @click.stop="handleSave">
                {{ $t("shops.save") }}
            </button>
            <router-link class="inline-block mb-2 mr-2 rounded-lg sk-btn sk-btn-primary btn-border"
                :to="{ name: 'resort-requirements' }">
                {{ $t("shops.cancel") }}
            </router-link>
        </div>
    </div>
</template>

<script>
import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import LocalizedContent from '@/modules/Shared/Components/form/LocalizedContent'
import ElementService from '@/modules/Shared/Pages/sections/ElementService'
import loader from "@/modules/Shared/Mixins/loader";
import DatatableService from '@/modules/Shared/Pages/sections/DatatableService';
import { v4 as uuidv4 } from 'uuid';
import store from "@/modules/Shared/Store/store";

export default {
    name: 'EditRequirement',
    mixins: [loader],
    components: {
        LabelForm,
        LocalizedContent
    },
    data() {
        return {
            requirement: {
                uuid: uuidv4(),
                key: '',
                type: '',
                langs: [],
                resort_uuid: this.$route.params.uuid,
            },
            service: new ElementService('v4/resorts/requirements'),
            tableService: new DatatableService('v4/resorts/requirements', { resort_uuid: this.$route.params.uuid }),
            selectedLanguage: null,
        }
    },
    async beforeRouteEnter (to, from, next) {
        if (to.params.elementUuid === undefined) {
            next()
            return
        }
        const service = new ElementService('v4/resorts/requirements')
        const requirement = await service.fetchElement(to.params.elementUuid)
        await store.dispatch('setRouteElement', requirement)
        next(vm => vm.requirement = requirement)
    },
    methods: {
        isNewRequirement() {
            return this.$route.params.elementUuid === undefined
        },

        async handleSave() {
            await this.loadAndNotify(async () => {
                if (this.isNewRequirement()) {
                    await this.service.createElement(this.requirement.uuid, this.requirement)
                }

                if (!this.isNewRequirement()) {
                    await this.service.editElement(this.requirement.uuid, this.requirement)
                }

                this.$router.push({ name: 'resort-requirements' })
            })
        },
    }
}
</script>
